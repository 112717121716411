import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import { cleanObject } from '@cvt/helpers/object';

import { cacheKeys } from '../config';
import { userClient } from '../client/userClient';

type Options = {
  enabled: boolean
  suspense: boolean,
  refetchInterval: number;
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

const defaultPageSize = 20;

export const useUsers = (params: Users.GetListParams = {}, options: Partial<Options> = defaultOptions) => {
  const queryClient = useQueryClient();

  // Remove undefined values
  const filters = cleanObject(params);

  const { data: { pages = [] } = {}, ...rest } = useInfiniteQuery({
    queryKey: [cacheKeys.getUsers, ...Object.values(filters)],
    queryFn: ({ pageParam = 1 }) => userClient.getUsers({
      limit: filters.limit || defaultPageSize,
      offset: (pageParam - 1) * (filters.limit || defaultPageSize),
      ...filters,
    }),
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = Math.ceil((lastPage.data.count || 0) / (filters.limit || defaultPageSize));
      const currentPage = allPages.length;
      if (currentPage < totalPages) {
        return currentPage + 1;
      }
      return undefined;
    },
    onSuccess: data => {
      data.pages.map(page => page.data.results.forEach(user => {
        queryClient.setQueryData([cacheKeys.getUser, user.id.toString()], {
          ...data,
          data: user,
        });
      }));
    },
    enabled: options.enabled,
    suspense: options.suspense,
    refetchInterval: options.refetchInterval,
    ...options,
  });

  return {
    ...rest,
    count: pages[0]?.data.count || 0,
    users: pages?.filter(p => p !== undefined).map(p => p?.data.results).flat(),
  };
};
