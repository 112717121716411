import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { ParticipantsField, Props as ParticipantsFieldProps } from '../components/ParticipantsField';

export type FormCrud = {
  users: (Communities.ExtendedCommunityUser| Communities.Channels.ChannelUser)[];
};

type Fields = keyof (FormCrud & {
  submit: boolean;
});


export interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: FormCrud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
  fieldProps?: Partial<{
    users: Partial<ParticipantsFieldProps<Communities.ExtendedCommunityUser | Communities.Channels.ChannelUser>>;
  }>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  users: [],
};


export const CommunityMembersForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields, ...props }) => {
  const dictionary = useDictionary();

  const { handleSubmit, control, formState: { isSubmitting, isDirty } } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest(data);
    } catch (err) {
      console.error(err);
    }
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Grid height="100%" container spacing={3}>
        {isFieldVisible('users') && (
          <Grid item xs={12}>
            <Controller
              name="users"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <ParticipantsField
                  {...field}
                  users={field.value}
                  defaultUsers={defaultValues.users}
                  placeholder={dictionary.forms.community.fieldAddMembersPlaceholder}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('users')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('submit') && (
          <Grid mt="auto" item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
